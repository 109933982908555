import React from 'react'
import FounderImage from "../FounderImage.png"
import "../css/HomePageAboutFounder.css"

function HomePageAboutFounder() {
    return (
        <div className='container-fluid' style={{ padding: "0px" }}>
            <div className='row'>
                {/* Hide or reduce the size of col-1 on small screens */}
                <div className='col-md-1 d-none d-md-block' style={{ backgroundColor: "#E5E4E2" }}></div>
                <div className='col-12 col-md-3 d-flex justify-content-center align-items-center' style={{ paddingTop: "20px" }}>
                    <img 
                        src={FounderImage} 
                        alt="Founder" 
                        className="img-fluid img-border" 
                        style={{ maxWidth: '250px' }}
                    />
                </div>
                <div className='col-12 col-md-7 d-flex justify-content-center align-items-center' style={{ paddingTop: "45px", paddingBottom: "45px" }}>
                    <p className='responsive-text-small HomePageAboutFounder_bottom-margins' style={{ marginRight: "4%", marginLeft: "3%" }}>
                        <i>Zainab Abdus-Saboor is the founder and owner of My Literacy Center. LLC. She is 
                        a Certified NC Professional Educator, Orton-Gillingham Reading Specialist and 
                        Montessori Teacher Assistant. She has been tutoring since 2009 and teaching 
                        Elementary since 2013. In 2015, while teaching 5th grade English Language Arts, 
                        she designed My Literacy Center with the vision to provide students with motivational 
                        and engaging learning experiences.</i> 
                    </p>                    
                </div>
                <div className='col-md-1 d-none d-md-block' style={{ backgroundColor: "#E5E4E2" }}></div>
            </div>

            <div className='row'>
                <div className='col-md-1 d-none d-md-block' style={{ backgroundColor: "#E5E4E2" }}></div>
                <div className='col-12 col-md-10 justify-content-center align-items-center'>
                    <p 
                    className='responsive-text-small HomePageAboutFounder_padding' 
                    style={{ 
                            marginLeft: "3%",
                            marginRight: "4%",
                            marginTop: "2%"
                         }}>
                        As a young child, I thought myself less intelligent than my peers. I remained behind 
                        and received low scores, though was pushed forward to the next grade. Confused and 
                        distressed, my parents decided that homeschooling would allow me the time and comfort 
                        to learn what was needed at my own pace. Therefore, when I became an elementary teacher, 
                        I focused on my students’ comfort and morale. With the intention to benefit more 
                        students than those in my classroom, I established My Literacy Center in the summer 
                        of 2015 as a tutoring center. Today, My Literacy Center arranges tutoring services at 
                        a comparable rate, provides educational experiences for Preschool students, and offers 
                        resources for parents and educators to support child development and learning styles.
                    </p>
                </div>
                <div className='col-md-1 d-none d-md-block' style={{ backgroundColor: "#E5E4E2" }}></div>
            </div>

            <div className='row'>
                <div className='col-md-1 d-none d-md-block' style={{ backgroundColor: "#E5E4E2", paddingBottom: "20px" }}></div>
                <div className='col-12 col-md-10 justify-content-center align-items-center' style={{ paddingBottom: "20px" }}>
                    <p 
                    className='responsive-text-small' 
                    style={{ 
                            marginRight: "4%",
                            marginLeft: "3%"
                        }}>
                        We emphasize the importance of social-emotional development in our everyday activities. Progress 
                        reports are differentiated, and expectations are grounded on individualized understanding. We 
                        encourage teamwork and parent involvement. Our work environment is a home-like setting filled 
                        with tangible materials and options of floor, table, or standing positions. We incorporate student 
                        interests in activities and provide real-life connections in lessons.
                    </p>
                </div>
                <div className='col-md-1 d-none d-md-block' style={{ backgroundColor: "#E5E4E2", paddingBottom: "20px" }}></div>
            </div>
        </div>
    )
}

export default HomePageAboutFounder
