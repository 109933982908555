import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import '../css/Header.css'

const Header = () => {

  return (
    <div className='container-fluid'>

      <div className='row'>
        <div className='col-1' style={{ backgroundColor: "#5e4675", padding: "0px" }}></div>
        <div className='col-10' style={{ padding: "0px" }}>
          <header className='header' style={{ width: '100%', paddingLeft: "0px", paddingRight: "0px" }}>
            <nav className="navbar" style={{ width: '100%' }}>
              <Link to="/" className="nav-link Header__samller-navbar-items Header__no-margin-left" style={{ marginLeft: "20px" }}>Home</Link>
              <Link to="/services" className="nav-link Header__samller-navbar-items">Services</Link>
              <Dropdown style={{ display: "flex" }}>
                <Dropdown.Toggle variant="success" id="dropdown-basic" className='nav-link Header__additional-padding-bottom'>
                  <Link to="/tutoringapplication" className='dropdown-nav-link Header__samller-navbar-items'>Register</Link>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                        <Dropdown.Item as={Link} to="/tutoringapplication">Tutoring Application</Dropdown.Item>
                        <Dropdown.Item as={Link} to="/bookclub">Book Club Registration</Dropdown.Item>
                        <Dropdown.Item as={Link} to="/essay">Essay Submission</Dropdown.Item>
                        <Dropdown.Item as={Link} to="/readingevaluation">Reading Evaluation</Dropdown.Item>
                        <Dropdown.Item as={Link} to="/writing">Writer's Workshop</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown style={{ display: "flex" }}>
                <Dropdown.Toggle variant='success' id='dropdown-basic' className='nav-link Header__additional-padding-bottom'>
                        <Link to="/infocenter" className='dropdown-nav-link Header__samller-navbar-items'>Info Center</Link>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                        <Dropdown.Item as={Link} to="/workbooks">Workbooks</Dropdown.Item>
                        <Dropdown.Item as={Link} to="/onlineresources">Online Resources</Dropdown.Item>
                        <Dropdown.Item as={Link} to="/literature-corner">Literature Corner</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Link to="/contact-us" className="nav-link Header__samller-navbar-items Header__no-margin-right">Contact Us</Link>
            </nav>
          </header>
        </div>
        <div className='col-1' style={{ backgroundColor: "#5e4675", padding: "0px" }}></div>
      </div>

      <div className='row'>
        <div className='col-2' style={{ backgroundColor: "#5e4675" }}></div>
        <div className='col-8' style={{ padding: "0px" }}>
          <div className='div-header'>
            <h2 className='Header__responsive-h2 Header__title-margin-bottom' style={{ color: "white", margin: "0px" }}>My Literacy Center, LLC</h2>
          </div>
        </div>
        <div className='col-2' style={{ backgroundColor: "#5e4675" }}></div>
      </div>

      <div className='row'>
        <div className='col-2' style={{ backgroundColor: "#5e4675" }}></div>
        <div className='col-8' style={{ padding: "0px" }}>
          <div className='div-header' style={{ padding: "0px" }}>
            <h2 className='Header__responsive-h2' style={{ color: "#FDDB51", textShadow: "0 0 2px #CCCC00, 0 0 5px #CCCC00", paddingBottom: "30px" }}><i>Grasp. Apply. Perfect.</i></h2>
          </div>
        </div>
        <div className='col-2' style={{ backgroundColor: "#5e4675" }}></div>
      </div>

    </div>
  );
};

export default Header;

