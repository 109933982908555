import React from "react";
import { Link } from 'react-router-dom';

import "../css/Services.css"

class Services extends React.Component {

        render() {
                return (
                        <div className="container-fluid" style={{ padding: "0px" }}>
                                
                                <div className="row">

                                        <div className="col-md-1 d-none d-md-block" style={{ backgroundColor: "#E5E4E2" }}></div>

                                        <div className="col-12 col-md-10">

                                                <div className="row" style={{ paddingTop: "20px", paddingBottom: "40px" }}>
                                                        <hr 
                                                                style={{ 
                                                                        width: "90%", 
                                                                        margin: "auto", 
                                                                        height: "5px",
                                                                        border: "none",
                                                                        backgroundColor: "#36454F",
                                                                        opacity: "1"
                                                                }}
                                                        ></hr>
                                                        <h1 
                                                                className="responsive-h1 form-title" 
                                                                style={{ paddingTop: "30px", paddingBottom: "30px" }}
                                                        >Tutoring Services</h1>
                                                        <hr
                                                                style={{
                                                                        width: "90%",
                                                                        margin: "auto",
                                                                        height: "1px",
                                                                        border: "none",
                                                                        backgroundColor: "#6f0476",
                                                                        opacity: "1"
                                                                }}
                                                        ></hr>
                                                </div>

                                                <div className="row">
                                                        <div className="col-12 d-flex justify-content-center">
                                                                <div className="table-responsive Services__responsive-margins">
                                                                        <table>
                                                                                <tr>
                                                                                        <th className="Services__th responsive-text-small">Packages:</th>
                                                                                        <th className="Services__th responsive-text-small">Light</th>
                                                                                        <th className="Services__th responsive-text-small">Bright</th>
                                                                                        <th className="Services__th responsive-text-small">Brilliant</th>
                                                                                </tr>
                                                                                <tr>
                                                                                        <th className="Services__th responsive-text-small">8 sessions</th>
                                                                                        <td className="Services__td-1 responsive-text-small">$400</td>
                                                                                        <td className="Services__td-1 responsive-text-small">$500</td>
                                                                                        <td className="Services__td-1 responsive-text-small">$600</td>
                                                                                </tr>
                                                                                <tr>
                                                                                        <th className="Services__left-column responsive-text-small">3 Month Commitment Discount</th>
                                                                                        <td className="Services__td-2 responsive-text-small">$1050</td>
                                                                                        <td className="Services__td-2 responsive-text-small">$1350</td>
                                                                                        <td className="Services__td-2 responsive-text-small">$1650</td>
                                                                                </tr>
                                                                                <tr>
                                                                                        <th className="Services__left-column responsive-text-small">Evaluation</th>
                                                                                        <td className="Services__td-1 responsive-text-small">X</td>
                                                                                        <td className="Services__td-1 responsive-text-small">X</td>
                                                                                        <td className="Services__td-1 responsive-text-small">X</td>
                                                                                </tr>
                                                                                <tr>
                                                                                        <th className="Services__left-column responsive-text-small">Expert One on One Support</th>
                                                                                        <td className="Services__td-2 responsive-text-small">X</td>
                                                                                        <td className="Services__td-2 responsive-text-small">X</td>
                                                                                        <td className="Services__td-2 responsive-text-small">X</td>
                                                                                </tr>
                                                                                <tr>
                                                                                        <th className="Services__left-column responsive-text-small">Individualized Work Plan</th>
                                                                                        <td className="Services__td-1 responsive-text-small"></td>
                                                                                        <td className="Services__td-1 responsive-text-small">X</td>
                                                                                        <td className="Services__td-1 responsive-text-small">X</td>
                                                                                </tr>
                                                                                <tr>
                                                                                        <th className="Services__left-column responsive-text-small">Supplemental Learning Activities</th>
                                                                                        <td className="Services__td-2 responsive-text-small"></td>
                                                                                        <td className="Services__td-2 responsive-text-small"></td>
                                                                                        <td className="Services__td-2 responsive-text-small">X</td>
                                                                                </tr>
                                                                        </table>
                                                                </div>
                                                        </div>
                                                        <div className="row">
                                                                <div className="col-12 d-flex justify-content-center">
                                                                        <Link to="/tutoringapplication">
                                                                                <button 
                                                                                        type="submit" 
                                                                                        className="btn btn-success"
                                                                                        style={{ marginTop: "40px" }}
                                                                                >
                                                                                        Register for Tutoring Package
                                                                                </button>
                                                                        </Link>
                                                                </div>
                                                        </div>
                                                </div>

                                                <div className="row" style={{ paddingTop: "26px", paddingBottom: "20px" }}>
                                                        <hr 
                                                                style={{ 
                                                                        width: "90%", 
                                                                        margin: "auto", 
                                                                        height: "5px",
                                                                        border: "none",
                                                                        backgroundColor: "#36454F",
                                                                        opacity: "1"
                                                                }}
                                                        ></hr>
                                                        <h1 
                                                                className="responsive-h1 form-title" 
                                                                style={{ paddingTop: "30px", paddingBottom: "30px" }}
                                                        >
                                                                Reading Evaluation
                                                        </h1>
                                                        <hr
                                                                style={{
                                                                        width: "90%",
                                                                        margin: "auto",
                                                                        height: "1px",
                                                                        border: "none",
                                                                        backgroundColor: "#6f0476",
                                                                        opacity: "1"
                                                                }}
                                                        ></hr>
                                                </div>

                                                <div className="row">
                                                        <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                                                                <p className="responsive-text-small" style={{ marginBottom: "0px" }}>KG-8th Grade</p>
                                                                <p className="responsive-text-small" style={{ marginBottom: "0px" }}>60-90 Minutes</p>
                                                                <p className="responsive-text-small" style={{ marginBottom: "0px" }}>1-on-1, in person</p>
                                                                <p className="responsive-text-small" style={{ marginBottom: "0px" }}>Fluency, Comprehension, Writing</p>
                                                                <p className="responsive-text-small" style={{ marginBottom: "0px" }}>$60 per test</p>
                                                                <Link to="/readingevaluation">
                                                                        <button 
                                                                                type="submit" 
                                                                                className="btn btn-success"
                                                                                style={{ marginTop: "15px" }}
                                                                        >
                                                                                Reading Evaluation Form
                                                                        </button>
                                                                </Link>
                                                        </div>
                                                </div>

                                                <div className="row" style={{ paddingTop: "26px", paddingBottom: "20px" }}>
                                                        <hr 
                                                                style={{ 
                                                                        width: "90%", 
                                                                        margin: "auto", 
                                                                        height: "5px",
                                                                        border: "none",
                                                                        backgroundColor: "#36454F",
                                                                        opacity: "1"
                                                                }}
                                                        ></hr>
                                                        <h1 
                                                                className="responsive-h1 form-title" 
                                                                style={{ paddingTop: "30px", paddingBottom: "30px" }}
                                                        >
                                                                Writer's Workshop
                                                        </h1>
                                                        <hr
                                                                style={{
                                                                        width: "90%",
                                                                        margin: "auto",
                                                                        height: "1px",
                                                                        border: "none",
                                                                        backgroundColor: "#6f0476",
                                                                        opacity: "1"
                                                                }}
                                                        ></hr>
                                                </div>

                                                <div className="row">
                                                        <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                                                                <p className="responsive-text-small" style={{ marginBottom: "0px" }}>Remedial Writing Course</p>
                                                                <p className="responsive-text-small" style={{ marginBottom: "0px" }}>Group setting</p>
                                                                <p className="responsive-text-small" style={{ marginBottom: "0px" }}>$50 per student</p>
                                                                <p className="responsive-text-small" style={{ marginBottom: "0px" }}>See flyer for details</p>
                                                                <Link to="/writing">
                                                                        <button 
                                                                                type="submit" 
                                                                                className="btn btn-success"
                                                                                style={{ marginTop: "15px" }}
                                                                        >
                                                                                Writer's Workshop Form
                                                                        </button>
                                                                </Link>
                                                        </div>
                                                </div>

                                                <div className="row" style={{ paddingTop: "26px", paddingBottom: "20px" }}>
                                                        <hr 
                                                                style={{ 
                                                                        width: "90%", 
                                                                        margin: "auto", 
                                                                        height: "5px",
                                                                        border: "none",
                                                                        backgroundColor: "#36454F",
                                                                        opacity: "1"
                                                                }}
                                                        ></hr>
                                                        <h1 
                                                                className="responsive-h1 form-title" 
                                                                style={{ paddingTop: "30px", paddingBottom: "30px" }}
                                                        >
                                                                Essay Edits
                                                        </h1>
                                                        <hr
                                                                style={{
                                                                        width: "90%",
                                                                        margin: "auto",
                                                                        height: "1px",
                                                                        border: "none",
                                                                        backgroundColor: "#6f0476",
                                                                        opacity: "1"
                                                                }}
                                                        ></hr>
                                                </div>

                                                <div className="row">
                                                        <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                                                                <p className="responsive-text-small" style={{ marginBottom: "0px" }}>3-5 days to review</p>
                                                                <p className="responsive-text-small" style={{ marginBottom: "0px" }}>$30/page</p>
                                                                <p className="responsive-text-small" style={{ marginBottom: "0px" }}>$30 per conference (optional)</p>
                                                                <p className="responsive-text-small" style={{ marginBottom: "0px" }}>Expedited: $40/page</p>
                                                                <Link to="/essay">
                                                                        <button 
                                                                                type="submit" 
                                                                                className="btn btn-success"
                                                                                style={{ marginTop: "15px", marginBottom: "50px" }}
                                                                        >
                                                                                Essay Submission Form
                                                                        </button>
                                                                </Link>
                                                        </div>
                                                </div>

                                        </div>

                                        <div className="col-md-1 d-none d-md-block" style={{ backgroundColor: "#E5E4E2" }}></div>

                                </div>

                        </div>
                )
        }
}

export default Services