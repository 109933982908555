import React from 'react'

function HomePageFocusQuote() {

  return(
    <div className='container-fluid' style={{ padding: "0px" }}>
      
      <div className='row' style={{ paddingTop: "30px", paddingBottom: "30px", backgroundColor: "#D0E8EE" }}>
        <hr style={{ width: "75%", margin: "auto" }}></hr>
        <div className='row'>
          <div className='col-2'></div>
          <div className='col-8'>
            <p 
            className='responsive-text' 
            style={{ 
              textAlign: "center", 
              marginTop: "20px", 
              marginBottom: "20px",
              marginRight: "10%",
              marginLeft: "10%"
              }}>
              <i>At My Literacy Center, the main focus is the students and their satisfaction and motivation throughout the learning experience.</i>
            </p>
          </div>
          <div className='col-2'></div>
        </div>
        <hr style={{ width: "75%", margin: "auto" }}></hr>
      </div>

    </div>
  )

}

export default HomePageFocusQuote
