import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import getStripe from '../GetStripe';
import "../css/WriterWorkshopRegistrationForm.css"

function WriterWorkshopRegistrationForm() {

    const [formData, setFormData] = useState({
        studentFullName: '',
        studentDOB: '',
        studentHomeAddress: '',
        parentFullName: '',
        parentPhoneNumber: '',
        parentEmail: '',
        cancellationPolicy: '',
        consent: '',
        writingSampleUpload: null
    })

    const sigCanvas = useRef({})

    const clear = () => { sigCanvas.current.clear() }

    const save = () => {
        const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
        console.log(signature)
    }

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };  

    const handleUploadFile = (event) => {
        setFormData(prevState => ({
            ...prevState,
            writingSampleUpload: event.target.files[0]
        }))
    }

    async function handleCheckout(event) {
        event.preventDefault();
        const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        console.log('Form Data: ', formData)
        const formDataToSend = new FormData();
        formDataToSend.append('studentFullName', formData.studentFullName);
        formDataToSend.append('studentDOB', formData.studentDOB);
        formDataToSend.append('studentHomeAddress', formData.studentHomeAddress);
        formDataToSend.append('parentFullName', formData.parentFullName);
        formDataToSend.append('parentPhoneNumber', formData.parentPhoneNumber);
        formDataToSend.append('parentEmail', formData.parentEmail);
        formDataToSend.append('cancellationPolicy', formData.cancellationPolicy ? 'True' : 'False');
        formDataToSend.append('consent', formData.consent ? 'True': 'False')
        formDataToSend.append('signature', signature);

        if (formData.essayUpload) {
                formDataToSend.append('writingSampleUpload', formData.writingSampleUpload);
        }

        try {
                const response = await fetch('https://myliteracycenter.pythonanywhere.com/forms/writer-workshop/', {
                    method: 'POST',
                    body: formDataToSend 
                });
        
                if (response.ok) {
                    console.log('Form submitted successfully');
                    alert("Thank you; your form was submitted successfully!");
                } else {
                    console.error('Error submitting form');
                    alert('Error submitting form; please try again later. If this problem persists, please reach out to Office@MyLiteracyCenter.org')
                }
            } catch (error) {
                console.error('Error submitting form:', error);
            }
        const stripe = await getStripe()
        const { error } = await stripe.redirectToCheckout({
            lineItems: [{ price: 'price_1PskUaRvLFdAUSWUODkrOsdV', quantity: 1 }],
            mode: 'payment',
            successUrl: window.location.origin + '/',
            cancelUrl: window.location.origin + '/',
            customerEmail: formData.parentEmail,
        })

        if (error) {
            console.error('Checkout error: ', error.message)
            alert("Checkout error: " + error.message)
        }
    }

    return (
        <div className='container-fluid' style={{ padding: "0px" }}>

            <div className='row'>
                <div className='col-md-1 d-none d-md-block' style={{ backgroundColor: "#E5E4E2" }}></div>
                <div className='col-12 col-10 d-flex justify-content-center align-items-center'>

                    <div className="row flex-grow-1" style={{ paddingTop: "20px", paddingBottom: "60px" }}>
                        <hr 
                            style={{ 
                                width: "90%", 
                                margin: "auto", 
                                height: "5px",
                                border: "none",
                                backgroundColor: "#36454F",
                                opacity: "1"
                            }}
                        ></hr>
                        <h1 
                            className="responsive-h1 form-title" 
                            style={{ paddingTop: "60px", paddingBottom: "30px" }}
                        >
                            Writer's Workshop Registration Form
                        </h1>
                        <form onSubmit={handleCheckout} style={{ paddingLeft: "10%", paddingRight: "10%"}}>
                            <div className='row' style={{ paddingBottom: "20px" }}>
                                <div className='col-6'>
                                    <div className='form-group'>
                                        <label htmlFor='studentFullName' className='form-text'>Student Full Name</label>
                                        <input
                                            type='text'
                                            name='studentFullName'
                                            id='studentFullName'
                                            className='form-control'
                                            onChange={handleChange}
                                            value={formData.studentFullName}
                                        />
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <label htmlFor='studentDOB' className='form-text'>Student Date of Birth</label>
                                    <input
                                        type='date'
                                        name='studentDOB'
                                        id='studentDOB'
                                        className='form-control'
                                        onChange={handleChange}
                                        value={formData.studentDOB}
                                    />
                                </div>
                            </div>
                            <div className='form-group'>
                                <label htmlFor='studentHomeAddress' className='form-text'>Student Home Address</label>
                                <input
                                    type='text'
                                    name='studentHomeAddress'
                                    id='studentHomeAddress'
                                    onChange={handleChange}
                                    value={formData.studentHomeAddress}
                                    className='form-control'
                                />
                            </div>
                            <div className='row' style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                                <div className='col-6'>
                                    <div className='form-group'>
                                        <label htmlFor='parentFullName' className='form-text'>Parent Full Name</label>
                                        <input
                                            type='text'
                                            id='parentFullName'
                                            className='form-control'
                                            name='parentFullName'
                                            value={formData.parentFullName}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='form-group'>
                                        <label htmlFor='parentPhoneNumber' className='form-text'>Parent Phone Number</label>
                                        <input
                                            type='tel'
                                            name='parentPhoneNumber'
                                            id='parentPhoneNumber'
                                            className='form-control'
                                            value={formData.parentPhoneNumber}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='form-group' style={{ paddingBottom: "15px" }}>
                                <label htmlFor='parentEmail' className='form-text'>Parent Email</label>
                                <input
                                    type='email'
                                    name='parentEmail'
                                    id='parentEmail'
                                    value={formData.parentEmail}
                                    onChange={handleChange}
                                    className='form-control'
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="file"
                                    className="form-control"
                                    id="writingSampleUpload"
                                    name="writingSampleUpload"
                                    style={{ display: 'none' }} 
                                    onChange={handleUploadFile}
                                    accept=".pdf,.doc,.docx"
                                />
                                <button 
                                    onClick={() => document.getElementById('writingSampleUpload').click()} 
                                    className="btn btn-primary"
                                    style={{ backgroundColor: "purple" }}
                                >
                                    Upload Writing Sample (This can be a picture of a story, poem, or paragraph of information that your child has written or a sample of something written while at school this past school year.)
                                </button>
                            </div>
                            <div className="form-group" style={{ paddingTop: "20px" }}>
                                <div className="form-check">
                                    <input 
                                        className="form-check-input" 
                                        type="checkbox" 
                                        id="cancellationPolicy" 
                                        onChange={handleChange}
                                        name="cancellationPolicy" 
                                        checked={formData.cancellationPolicy}
                                        required
                                    />
                                    <label className="form-check-label" htmlFor="cancellationPolicy">
                                        I understand that this writer's workshop is a 3 day course for students interested in practicing and elaborating on their writing skills. I also understand that if my child misses some time or even a day of this course, I will not be reimbursed.
                                    </label>
                                </div>
                            </div>
                            <div className="form-group" style={{ paddingTop: "20px" }}>
                                <div className="form-check">
                                    <input 
                                        className="form-check-input" 
                                        type="checkbox" 
                                        id="consent" 
                                        onChange={handleChange}
                                        name="consent" 
                                        checked={formData.consent}
                                        required
                                    />
                                    <label className="form-check-label" htmlFor="consent">
                                        I understand that my child will be expected to participate to gain the best experience. Activities will include narrative writing, persuasive writing, grammar and language skills. All supplies needed will be provided and there will not be a grade given at the conclusion of this course.
                                    </label>
                                </div>
                            </div>
                            <div className="form-group" style={{ paddingTop: "20px" }}>
                                <label className='form-text'>Signature:</label>
                                <SignatureCanvas
                                    penColor='black'
                                    canvasProps={{className: 'signatureCanvas'}}
                                    ref={sigCanvas}
                                />
                                <button 
                                    type="button" 
                                    className="btn btn-secondary" 
                                    onClick={clear}
                                    style={{ marginRight: "10px" }}
                                >
                                    Clear Signature
                                </button>
                                <button type="button" className="btn btn-primary" onClick={save}>Save Signature</button>
                            </div>
                            <button 
                                type="submit" 
                                className="btn btn-success"
                                style={{ marginTop: "40px" }}
                            >
                                Submit & Pay: $50
                            </button>
                        </form>
                    </div>

                </div>
                <div className='col-md-1 d-none d-md-block' style={{ backgroundColor: "#E5E4E2" }}></div>
            </div>

        </div>
    )

}

export default WriterWorkshopRegistrationForm