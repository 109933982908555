import React from "react";

import "../css/OnlineResources.css"
import CommonCoreImage from "../images/CCSS-Logo.jpg"
import NcEssentialStandardsImage from "../images/NcEssentialStandards.jpeg"
import BrainPop from "../images/BrainPop.png"
import Epic from "../images/Epic.jpg"
import MobyMax from "../images/MobyMax.png"
import Abcya from "../images/Abcya.png"
import StudyIsland from "../images/StudyIsland.png"
import Kahoot from "../images/Kahoot.jpg"
import Quizlet from "../images/Quizlet.png"

function OnlineResources() {

        return (
                <div className="container-fluid" style={{ padding: "0px" }}>

                        <div className="row">

                                <div className='col-1' style={{ backgroundColor: "#E5E4E2" }}></div>

                                <div className="col-10">

                                        <div className="row" style={{ paddingTop: "20px", paddingBottom: "60px" }}>

                                                <h1 className="responsive-h1 form-title">Online Resources</h1>

                                                <div className="row" style={{ paddingTop: "10px" }}>
                                                        <h1 className="responsive-h2 OnlineResources__text-margins">Standards</h1>
                                                        <div className="col-6">
                                                                <div style={{ marginLeft: "10%" }}>
                                                                        <a href="http://www.corestandards.org/">
                                                                                <img 
                                                                                        src={CommonCoreImage} 
                                                                                        alt="Standards" 
                                                                                        className="img-fluid" 
                                                                                        style={{ 
                                                                                                cursor: "pointer",
                                                                                                width: "250px",
                                                                                                height: "150px",
                                                                                                border: "3px solid #FF5C5C",
                                                                                                borderRadius: "5px"
                                                                                        }} 
                                                                                />
                                                                        </a>
                                                                </div>
                                                        </div>
                                                        <div className="col-6">
                                                                <div style={{ marginRight: "10%" }}>
                                                                        <a href="http://www.dpi.state.nc.us/curriculum/">
                                                                                <img
                                                                                        src={NcEssentialStandardsImage}
                                                                                        alt="NC Standards"
                                                                                        className="img-fluid"
                                                                                        style={{ 
                                                                                                cursor: "pointer", 
                                                                                                width: "300px", 
                                                                                                height: "150px",
                                                                                                border: "3px solid #FF5C5C",
                                                                                                borderRadius: "5px" 
                                                                                        }}
                                                                                />
                                                                        </a>
                                                                </div>
                                                        </div>
                                                </div>

                                                <div className="row" style={{ paddingTop: "60px" }}>
                                                        <h1 className="responsive-h2 OnlineResources__text-margins">Videos and Audiobooks</h1>
                                                        <div className="col-6">
                                                                <div style={{ marginLeft: "10%" }}>
                                                                        <a href="https://www.brainpop.com/">
                                                                                <img 
                                                                                        src={BrainPop} 
                                                                                        alt="BrainPop" 
                                                                                        className="img-fluid" 
                                                                                        style={{ 
                                                                                                cursor: "pointer",
                                                                                                width: "250px",
                                                                                                height: "150px",
                                                                                                border: "3px solid #FF5C5C",
                                                                                                borderRadius: "5px"
                                                                                        }} 
                                                                                />
                                                                        </a>
                                                                </div>
                                                        </div>
                                                        <div className="col-6">
                                                                <div style={{ marginRight: "10%" }}>
                                                                        <a href="https://www.getepic.com/">
                                                                                <img
                                                                                        src={Epic}
                                                                                        alt="Epic"
                                                                                        className="img-fluid"
                                                                                        style={{ 
                                                                                                cursor: "pointer", 
                                                                                                width: "300px", 
                                                                                                height: "150px",
                                                                                                border: "3px solid #FF5C5C",
                                                                                                borderRadius: "5px"
                                                                                        }}
                                                                                />
                                                                        </a>
                                                                </div>
                                                        </div>
                                                </div>

                                                <div className="row" style={{ paddingTop: "60px" }}>
                                                        <h1 className="responsive-h2 OnlineResources__text-margins">Assignments/Extra Practice</h1>
                                                        <div className="col-4">
                                                                <div style={{ marginLeft: "15%" }}>
                                                                        <a href="https://www.mobymax.com/curriculum/overview">
                                                                                <img 
                                                                                        src={MobyMax} 
                                                                                        alt="MobyMax" 
                                                                                        className="img-fluid" 
                                                                                        style={{ 
                                                                                                cursor: "pointer",
                                                                                                width: "250px",
                                                                                                height: "200px",
                                                                                                border: "3px solid #FF5C5C",
                                                                                                borderRadius: "5px"
                                                                                        }} 
                                                                                />
                                                                        </a>
                                                                </div>
                                                        </div>
                                                        <div className="col-4">
                                                                <div>
                                                                        <a href="https://www.abcya.com/">
                                                                                <img 
                                                                                        src={Abcya} 
                                                                                        alt="abcya" 
                                                                                        className="img-fluid" 
                                                                                        style={{ 
                                                                                                cursor: "pointer",
                                                                                                width: "175px",
                                                                                                height: "200px",
                                                                                                border: "3px solid #FF5C5C",
                                                                                                borderRadius: "5px"
                                                                                        }} 
                                                                                />
                                                                        </a>
                                                                </div>
                                                        </div>
                                                        <div className="col-4">
                                                                <div style={{ marginRight: "15%" }}>
                                                                        <a href="https://www.edmentum.com/products/study-island/">
                                                                                <img 
                                                                                        src={StudyIsland} 
                                                                                        alt="Study Island" 
                                                                                        className="img-fluid" 
                                                                                        style={{ 
                                                                                                cursor: "pointer",
                                                                                                width: "500px",
                                                                                                height: "200px",
                                                                                                border: "3px solid #FF5C5C",
                                                                                                borderRadius: "5px"
                                                                                        }} 
                                                                                />
                                                                        </a>
                                                                </div>
                                                        </div>
                                                </div>

                                                <div className="row" style={{ paddingTop: "60px" }}>
                                                        <h1 className="responsive-h2 OnlineResources__text-margins">Test Prep</h1>
                                                        <div className="col-6">
                                                                <div style={{ marginLeft: "10%" }}>
                                                                        <a href="https://kahoot.it/">
                                                                                <img 
                                                                                        src={Kahoot} 
                                                                                        alt="Kahoot" 
                                                                                        className="img-fluid" 
                                                                                        style={{ 
                                                                                                cursor: "pointer",
                                                                                                width: "250px",
                                                                                                height: "150px",
                                                                                                border: "3px solid #FF5C5C",
                                                                                                borderRadius: "5px"
                                                                                        }} 
                                                                                />
                                                                        </a>
                                                                </div>
                                                        </div>
                                                        <div className="col-6">
                                                                <div style={{ marginRight: "10%" }}>
                                                                        <a href="https://quizlet.com/features/flashcards">
                                                                                <img
                                                                                        src={Quizlet}
                                                                                        alt="Quizlet"
                                                                                        className="img-fluid"
                                                                                        style={{ 
                                                                                                cursor: "pointer", 
                                                                                                width: "300px", 
                                                                                                height: "150px",
                                                                                                border: "3px solid #FF5C5C",
                                                                                                borderRadius: "5px"
                                                                                        }}
                                                                                />
                                                                        </a>
                                                                </div>
                                                        </div>
                                                </div>

                                        </div>

                                </div>

                                <div className='col-1' style={{ backgroundColor: "#E5E4E2" }}></div>

                        </div>

                </div>
        )

}

export default OnlineResources