import React from 'react'
import HomePageFocusQuote from './HomePageFocusQuote'
import HomePageAboutFounder from './HomePageAboutFounder'
import HomePageMissionQuote from './HomePageMissionQuote'
import HomePageGraphic from './HomePageGraphics'

function HomePage() {

  return(
    <div className='container-fluid'>
      
      <HomePageFocusQuote />

      <HomePageAboutFounder />

      <HomePageMissionQuote />

      <HomePageGraphic />

    </div>
  )

}

export default HomePage
