import React from 'react'

function HomePageMissionQuote() {

    return (
        <div className='container-fluid' style={{ padding: "0px" }}>
            <div className='row'>
                {/* Hide col-1 on small screens */}
                <div className='col-md-1 d-none d-md-block' style={{ backgroundColor: "#E5E4E2" }}></div>
                <div className='col-12 col-md-10'>
                    <hr style={{ width: "95%", margin: "auto" }}></hr>
                    <div className='row'>
                        {/* Adjust padding/margin for mobile */}
                        <div className='col-12 col-md-2'></div>
                        <div className='col-12 col-md-8'>
                            <p className='responsive-text' 
                            style={{ 
                                textAlign: "center", 
                                marginTop: "20px", 
                                marginBottom: "20px",
                                marginLeft: "2%",
                                marginRight: "2%" 
                                }}>
                                <i>MLC's mission is for students to gain confidence and independence to complete the given task, 
                                activity, subject or grade by taking ownership of their learning through individualized 
                                interaction and guidance.</i>
                            </p>
                        </div>
                        <div className='col-12 col-md-2'></div>
                    </div>
                    <hr style={{ width: "95%", margin: "auto" }}></hr>
                </div>
                <div className='col-md-1 d-none d-md-block' style={{ backgroundColor: "#E5E4E2" }}></div>
            </div>
        </div>
    )
}

export default HomePageMissionQuote
