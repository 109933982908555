import React, { useEffect } from 'react';

function Success() {

    useEffect(() => {
        // Retrieve form data and endpoint from sessionStorage
        const storedFormData = sessionStorage.getItem('formData');
        const storedFormEndpoint = sessionStorage.getItem('formEndpoint');

        if (storedFormData && storedFormEndpoint) {
            const formData = JSON.parse(storedFormData);
            const formEndpoint = storedFormEndpoint;

            const submitFormData = async () => {
                try {
                    const response = await fetch(`https://myliteracycenter.pythonanywhere.com${formEndpoint}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(formData)
                    });

                    if (response.ok) {
                        console.log('Form submitted successfully');
                        alert("Thank you; your form was submitted successfully!");
                        sessionStorage.removeItem('formData');  
                        sessionStorage.removeItem('formEndpoint'); 
                    } else {
                        console.error('Error submitting form');
                    }
                } catch (error) {
                    console.error('Error submitting form:', error);
                }
            };

            submitFormData();
        } else {
            console.error('No form data or endpoint found in sessionStorage');
        }
    }, []);

    return (
        <div>
            <h1>Payment Successful</h1>
            <p>Your order has been placed, and your form data has been submitted.</p>
        </div>
    );
}

export default Success;
