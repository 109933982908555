import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';
import TutoringApplication from './components/TutoringApplication';
import BookClubRegistration from './components/BookClubRegistration';
import WorkbooksForm from './components/WorkbooksForm';
import WriterWorkshopRegistrationForm from './components/WriterWorkshopRegistrationForm';
import Services from './components/Services';
import ReadingEvaluationForm from './components/ReadingEvaluationForm';
import EssaySubmissionForm from './components/EssaySubmissionForm';
import InfoCenter from './components/InfoCenter';
import OnlineResources from './components/OnlineResources';
import LiteratureCorner from './components/LiteratureCorner';
import ContactUs from "./components/ContactUs"
import Success from './components/Success';

function App() {
        return (
                <Router>
                        <Header />
                        <Routes>
                                <Route path="/" element={<HomePage />} />
                                <Route path="/tutoringapplication" element={<TutoringApplication />} />
                                <Route path="/bookclub" element={<BookClubRegistration />} />
                                <Route path="/writing" element={<WriterWorkshopRegistrationForm />} />
                                <Route path="/workbooks" element={<WorkbooksForm />} />
                                <Route path="/services" element={<Services />} />
                                <Route path="/readingevaluation" element={<ReadingEvaluationForm />} />
                                <Route path="/essay" element={<EssaySubmissionForm />} />
                                <Route path="/infocenter" element={<InfoCenter />} />
                                <Route path='/onlineresources' element={<OnlineResources />} />
                                <Route path='/literature-corner' element={<LiteratureCorner />} />
                                <Route path='/contact-us' element={<ContactUs />} />
                                <Route path="/success" element={<Success />} />
                        </Routes>
                        <Footer />
                </Router>
        );
}

export default App;
