import React, { useState } from 'react';
import "../css/Footer.css";

function Footer() {
  // State to store form data
  const [formData, setFormData] = useState({
    name: '',
    email: ''
  });

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://myliteracycenter.pythonanywhere.com/contact/mailing-list/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        console.log("Form submitted successfully");
        alert("Thank you for subscribing!");
        setFormData({ name: '', email: '' });
      } else {
        console.error("Error submitting form");
        alert('Error submitting form; please try again later.');
      }
    } catch (error) {
      console.error("Submission error:", error);
      alert('Error submitting form; please try again later.');
    }
  };

  // Function to handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <div className='container-fluid textured-background'>
      <div className='row' style={{ paddingBottom: "65px" }}>
        {/* On smaller screens, stack the content */}
        <div className='col-12 col-md-4 offset-md-2 justify-content-center align-items-center'>
          <div className='row' style={{ paddingTop: "20px" }}>
            <p className='responsive-text-small'>Leave us a Review on Google Reviews!</p>
            <p className='responsive-text-xsmall'>Join our mailing list</p>
          </div>
          <div className='row' style={{ paddingTop: "20px" }}>
            <form className="subscribe-form" onSubmit={handleSubmit}>
              <div className="input-group mb-3">
                <input 
                  type="text" 
                  className="form-control" 
                  placeholder="Name" 
                  aria-label="Name" 
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required 
                />
                <input 
                  type="email" 
                  className="form-control" 
                  placeholder="Email" 
                  aria-label="Email" 
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required 
                />
                <div className="input-group-append">
                  <button className="btn btn-dark" type="submit">Subscribe Now</button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className='col-12 col-md-4 d-flex justify-content-center align-items-center'>
          <div className="contact-info responsive-text-small text-center">
            <p>Copyright © 2022 My Literacy Center, LLC</p>
            <p>1127 Kildaire Farm Rd, Cary NC 27511</p>
            <p>Office@MyLiteracyCenter.org</p>
            <p>(919) 532-4413</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
