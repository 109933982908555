import React, { useRef } from "react";
import "../css/ContactUs.css";

function ContactUs() {

    const formRef = useRef()

    const sendEmail = async (e) => {
        e.preventDefault()
        const formData = new FormData(formRef.current)

        try {
            const response = await fetch(
                "https://myliteracycenter.pythonanywhere.com/contact/new-message/",
                {
                    method: "POST",
                    body: formData,
                }
            )

            if (response.ok) {
                formRef.current.reset()
                alert("Thank you; your form was submitted successfully!")
            } else {
                alert('Error submitting form; please try again later.')
            }
        } catch (error) {
            alert(error)
        }
    }

    return (
        <div className="container-fluid" style={{ padding: "0px" }}>
            {/* Header */}
            <div className="row" style={{ paddingTop: "20px", paddingBottom: "40px" }}>
                <hr 
                    style={{ 
                        width: "90%", 
                        margin: "auto", 
                        height: "5px",
                        border: "none",
                        backgroundColor: "#36454F",
                        opacity: "1"
                    }}
                ></hr>
                <h1 
                    className="responsive-h1 form-title" 
                    style={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                    Contact <i>My Literacy Center</i>
                </h1>
                <hr
                    style={{
                        width: "90%",
                        margin: "auto",
                        height: "1px",
                        border: "none",
                        backgroundColor: "#6f0476",
                        opacity: "1"
                    }}
                ></hr>
            </div>

            {/* Contact Info */}
            <div className="row">
                <div className="col-12 d-flex justify-content-center flex-wrap">
                    <p className="responsive-text-small" style={{ paddingRight: "30px" }}>
                        Email: <a href="mailto: Office@MyLiteracyCenter.org">Office@MyLiteracyCenter.org</a>
                    </p>
                    <p className="responsive-text-small" style={{ paddingRight: "30px" }}>
                        <a href="mailto: Zainab@MyLiteracyCenter.org">Zainab@MyLiteracyCenter.org</a>
                    </p>
                    <p className="responsive-text-small">Call or Text: (919) 532-4413</p>
                </div>
            </div>

            {/* Google Map - Make responsive */}
            <div className="row" style={{ paddingTop: "20px" }}>
                <div className="col-12">
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25893.994191551916!2d-78.79391014265254!3d35.781534793488895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89acf3107346e563%3A0xbfc3924ac4789052!2s1127%20Kildaire%20Farm%20Rd%2C%20Cary%2C%20NC%2027511!5e0!3m2!1sen!2sus!4v1724357362687!5m2!1sen!2sus" 
                        style={{ border: "0", width: "100%", height: "450px" }}
                        allowFullScreen="" 
                        loading="lazy" 
                        referrerPolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>
            </div>

            {/* Hours Section */}
            <div className="row" style={{ paddingTop: "20px", paddingBottom: "40px" }}>
                <hr 
                    style={{ 
                        width: "90%", 
                        margin: "auto", 
                        height: "5px",
                        border: "none",
                        backgroundColor: "#36454F",
                        opacity: "1"
                    }}
                ></hr>
                <h1 
                    className="responsive-h1 form-title" 
                    style={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                    Hours
                </h1>
                <hr
                    style={{
                        width: "90%",
                        margin: "auto",
                        height: "1px",
                        border: "none",
                        backgroundColor: "#6f0476",
                        opacity: "1"
                    }}
                ></hr>
            </div>

            <div className="row">
                <div className="col-12 d-flex flex-column align-items-center">
                    <p className="responsive-text-small" style={{ marginBottom: "0px" }}>Address: 1127 Kildaire Farm Road, Cary, NC</p>
                    <p className="responsive-text-small" style={{ marginBottom: "0px" }}>Monday-Thursday <strong>1:00pm-7:00pm</strong></p>
                    <p className="responsive-text-small" style={{ marginBottom: "0px" }}>*Saturday-Sunday <strong>10:00am-1:00pm</strong></p>
                    <p className="responsive-text-small" style={{ marginTop: "20px" }}>*Hours may vary depending on tutor availability.</p>
                </div>
            </div>

            {/* Contact Form */}
            <div className="row" style={{ paddingTop: "20px", paddingBottom: "40px" }}>
                <hr 
                    style={{ 
                        width: "90%", 
                        margin: "auto", 
                        height: "5px",
                        border: "none",
                        backgroundColor: "#36454F",
                        opacity: "1"
                    }}
                ></hr>
                <h1 
                    className="responsive-h1 form-title" 
                    style={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                    Contact Us
                </h1>
                <hr
                    style={{
                        width: "90%",
                        margin: "auto",
                        height: "1px",
                        border: "none",
                        backgroundColor: "#6f0476",
                        opacity: "1"
                    }}
                ></hr>
            </div>

            <div className="row" style={{ paddingBottom: "60px" }}>
                <div className="col-12 d-flex justify-content-center">
                    <form
                        ref={formRef}
                        onSubmit={sendEmail}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            maxWidth: "600px",  // Restrict max width on larger screens
                            padding: "0 15px"  // Add padding on smaller screens
                        }}
                    >
                        <label>Name:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            className="ContactUs__input"
                            required
                        />
                        <label>Email</label>
                        <input
                            type="email"
                            name="email"
                            className="ContactUs__input"
                            id="email"
                            required
                        />
                        <label>Subject</label>
                        <input
                            type="text"
                            id="subject"
                            name="subject"
                            className="ContactUs__input"
                            required
                        />
                        <label>Message</label>
                        <textarea
                            id="message"
                            name="message"
                            className="ContactUs__input"
                            rows="4"
                            required
                        />
                        <button
                            type="submit"
                            className="btn btn-success"
                            style={{ 
                                marginTop: "20px",
                                alignSelf: "flex-end"
                            }}
                        >
                            Send
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ContactUs;