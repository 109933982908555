import { loadStripe } from '@stripe/stripe-js';

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe('pk_live_51PAskwRvLFdAUSWUJ59cVdZ0roPqHsywx6dXodLp9gnAEVbDqttlhwYHhmM0d9xFGYFyEHU7IPxtaktWX1SGdKXL00UpQD6dyg');
//     stripePromise = loadStripe('pk_test_51PAskwRvLFdAUSWUEIfJl7CnAkWRo9Ej7kXXL6lLfmh7nldQBeb44lSLe2qYoqczplwVe905n59bDDWx67C7C8P200WY169UL5');
  }
  return stripePromise;
};

export default getStripe;
