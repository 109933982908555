import React from "react";
import { Link } from "react-router-dom";

import "../css/InfoCenter.css"

function InfoCenter() {

        return(
                <div className="container-fluid" style={{ padding: "0px" }}>

                        <div className="row">

                                <div className='col-1' style={{ backgroundColor: "#E5E4E2" }}></div>

                                <div className="col-10">

                                        <div className="row" style={{ paddingTop: "20px" }}>
                                                <hr 
                                                        style={{ 
                                                                width: "90%", 
                                                                margin: "auto", 
                                                                height: "5px",
                                                                border: "none",
                                                                backgroundColor: "#36454F",
                                                                opacity: "1"
                                                        }}
                                                ></hr>
                                                <h1 
                                                        className="responsive-h1 form-title" 
                                                        style={{ paddingTop: "10px" }}
                                                >
                                                        Welcome to the MLC Info Center
                                                </h1>
                                                <hr 
                                                        style={{ 
                                                                width: "90%", 
                                                                margin: "auto", 
                                                                height: "1px",
                                                                border: "none",
                                                                backgroundColor: "#36454F",
                                                                opacity: "1"
                                                        }}
                                                ></hr>
                                                <Link to="/workbooks">
                                                        <p 
                                                                className="responsive-text-medium InfoCenter__links InfoCenter__text-margins InfoCenter__text-font"
                                                                style={{ paddingTop: "15px" }}
                                                        >
                                                                <strong>Workbooks</strong> Subject Focused & Printable, Good for Homeschooling, Summer or Extra Practice
                                                        </p>
                                                </Link>
                                                <Link to="/onlineresources">
                                                        <p className="responsive-text-medium InfoCenter__links InfoCenter__text-margins InfoCenter__text-font">
                                                                <strong>Online Resources</strong>   Educational Games and Videos & Common Core and State Standards
                                                        </p>
                                                </Link>
                                                <Link to="/literature-corner">
                                                        <p className="responsive-text-medium InfoCenter__links InfoCenter__text-margins InfoCenter__text-font">
                                                                <strong>Literature Corner</strong>   Books by Theme & Articles, Poetry and Short Stories
                                                        </p>
                                                </Link>
                                                <hr 
                                                        style={{ 
                                                                width: "90%", 
                                                                margin: "auto", 
                                                                height: "1px",
                                                                border: "none",
                                                                backgroundColor: "#36454F",
                                                                opacity: "1"
                                                        }}
                                                ></hr>
                                        </div>
                                        
                                        <div className="row" style={{ paddingTop: "20px" }}>
                                                <p className="responsive-h4 form-title">
                                                        <strong>Frequently Asked Questions</strong>
                                                </p>
                                                <div className="col-12 InfoCenter__div-padding">
                                                        <div className="InfoCenter__div-outline">
                                                                <p 
                                                                        className="responsive-text-medium InfoCenter__text-font InfoCenter__links"
                                                                        style={{ paddingTop: "5px", paddingLeft: "20px" }}
                                                                >
                                                                        Where is the center located?  
                                                                </p>
                                                                <p
                                                                        className="responsive-text-small InfoCenter__text-font InfoCenter__links"
                                                                        style={{ paddingTop: "5px", paddingLeft: "20px", paddingBottom: "5px" }}
                                                                >
                                                                        The center is located at 1127 Kildaire Farm Road in Cary, NC. We do not offer online 
                                                                        sessions. We have two offices at this location and multiple tutors. 
                                                                </p>
                                                        </div>
                                                </div>
                                        </div>

                                        <div className="row" style={{ paddingTop: "20px" }}>
                                                <div className="col-12 InfoCenter__div-padding">
                                                        <div className="InfoCenter__div-outline">
                                                                <p 
                                                                        className="responsive-text-medium InfoCenter__text-font InfoCenter__links"
                                                                        style={{ paddingTop: "5px", paddingLeft: "20px" }}
                                                                >
                                                                        How long is a tutoring session?
                                                                </p>
                                                                <p
                                                                        className="responsive-text-small InfoCenter__text-font InfoCenter__links"
                                                                        style={{ paddingTop: "5px", paddingLeft: "20px", paddingBottom: "5px" }}
                                                                >
                                                                        A standard session will take 45-1 hour. Parents and students may always request an alternative.
                                                                </p>
                                                        </div>
                                                </div>
                                        </div>

                                        <div className="row" style={{ paddingTop: "20px" }}>
                                                <div className="col-12 InfoCenter__div-padding">
                                                        <div className="InfoCenter__div-outline">
                                                                <p 
                                                                        className="responsive-text-medium InfoCenter__text-font InfoCenter__links"
                                                                        style={{ paddingTop: "5px", paddingLeft: "20px" }}
                                                                >
                                                                        What happens if I am late or I miss a session? 
                                                                </p>
                                                                <p
                                                                        className="responsive-text-small InfoCenter__text-font InfoCenter__links"
                                                                        style={{ paddingTop: "5px", paddingLeft: "20px", paddingBottom: "5px" }}
                                                                >
                                                                        If you know you may miss a session or are running late, the best thing to do is contact the tutor 
                                                                        notifying him/her. If the tutor has already arrived to the session or he/she is in route before hearing 
                                                                        from you, you may be charged the full or partial amount of the session. If the tutor is notified 
                                                                        at least one hour before the session begins, no charge will apply. 
                                                                </p>
                                                        </div>
                                                </div>
                                        </div>

                                        <div className="row" style={{ paddingTop: "20px" }}>
                                                <div className="col-12 InfoCenter__div-padding">
                                                        <div className="InfoCenter__div-outline">
                                                                <p 
                                                                        className="responsive-text-medium InfoCenter__text-font InfoCenter__links"
                                                                        style={{ paddingTop: "5px", paddingLeft: "20px" }}
                                                                >
                                                                        What happens if the tutor is late or misses a session? 
                                                                </p>
                                                                <p
                                                                        className="responsive-text-small InfoCenter__text-font InfoCenter__links"
                                                                        style={{ paddingTop: "5px", paddingLeft: "20px", paddingBottom: "5px" }}
                                                                >
                                                                        When a tutor is late or misses a session, he/she is expected to work with the parent/student 
                                                                        to find a day to make up the missed time within the week. Parents/students will not be charged 
                                                                        for the missed time. 
                                                                </p>
                                                        </div>
                                                </div>
                                        </div>

                                        <div className="row" style={{ paddingTop: "20px", paddingBottom: "60px" }}>
                                                <div className="col-12 InfoCenter__div-padding">
                                                        <div className="InfoCenter__div-outline">
                                                                <p 
                                                                        className="responsive-text-medium InfoCenter__text-font InfoCenter__links"
                                                                        style={{ paddingTop: "5px", paddingLeft: "20px" }}
                                                                >
                                                                        Can I register multiple children?
                                                                </p>
                                                                <p
                                                                        className="responsive-text-small InfoCenter__text-font InfoCenter__links"
                                                                        style={{ paddingTop: "5px", paddingLeft: "20px", paddingBottom: "5px" }}
                                                                >
                                                                        Yes, multiple children can be registered, but you will have to complete a separate registration 
                                                                        form for each child. Please ask for available discounts for siblings. 
                                                                </p>
                                                        </div>
                                                </div>
                                        </div>

                                </div>

                                <div className='col-1' style={{ backgroundColor: "#E5E4E2" }}></div>

                        </div>

                </div>
        )

}

export default InfoCenter