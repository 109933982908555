import React from 'react'

import "../css/HomePageGraphics.css"

function HomePageGraphics() {

    return(
        <div className='container-fluid' style={{ padding: "0px" }}>

        <div className='row'>
                {/* Hide col-1 on small screens */}
                <div className='col-md-1 d-none d-md-block' style={{ backgroundColor: "#E5E4E2" }}></div>
                <div className='col-12 col-md-10 justify-content-center align-items-center'>
                    <p 
                    className='responsive-text-small' 
                    style={{ 
                        marginRight: "4%", 
                        marginLeft: "3%",
                        marginBottom: "0px",
                        paddingTop: "36px" 
                    }}>
                        We envision our students reaching their academic goals within 6 months. To ensure our 
                        students graduate from our program, we will:
                    </p>
                    <ul className='responsive-text-small' style={{ marginLeft: "3%", marginBottom: "0px" }}>
                        <li>provide each student with an individualized plan</li>
                        <li>communicate progress with specified parties</li>
                        <li>follow the Grasp, Apply, Perfect system​</li>
                    </ul>
                    <p 
                    className='responsive-text-small'
                    style={{
                        marginRight: "4%",
                        marginLeft: "3%"
                    }}>
                        Once the student and tutor meet and the individualized plan has been created, it's 
                        time to bridge the G.A.P. between what they know and what they will learn. Students will 
                        step through grasping, applying and perfecting concepts through engaging and tailored lessons.​
                    </p>
                </div>
                <div className='col-md-1 d-none d-md-block' style={{ backgroundColor: "#E5E4E2" }}></div>
            </div>

            <div className='row d-flex'>
                <div className='col-md-1 d-none d-md-block' style={{ backgroundColor: "#E5E4E2" }}></div>
                <div className='col flex-grow-10'>
                    <div className='row d-flex'>
                        <div 
                            className='col flex-grow-3 d-flex flex-column justify-content-center align-items-center HomePageGraphics__responsive-graphic-size'
                            id='evaluate-graphic'
                            style={{ marginLeft: "2%", marginTop: "10px", marginBottom: "20px", marginRight: "1%" }}
                        >
                            <h1 style={{ paddingTop: "60px", paddingBottom: "30px", color: "white", fontWeight: "bold" }}>01</h1>
                            <p className='graphic-text' style={{ paddingBottom: "60px", color: "white" }}>EVALUATE</p>
                        </div>
                        <div 
                            className='col flex-grow-3 d-flex flex-column justify-content-center align-items-center HomePageGraphics__responsive-graphic-size'
                            id='grasp-graphic'
                            style={{ marginTop: "10px", marginBottom: "20px", marginRight: "1%" }}
                        >
                            <h1 style={{ paddingTop: "60px", paddingBottom: "30px", color: "white", fontWeight: "bold" }}>02</h1>
                            <p className='graphic-text' style={{ paddingBottom: "60px", color: "white" }}>GRASP</p>
                        </div>
                        <div 
                            className='col flex-grow-3 d-flex flex-column justify-content-center align-items-center HomePageGraphics__responsive-graphic-size'
                            id='apply-graphic'
                            style={{ marginTop: "10px", marginBottom: "20px", marginRight: "1%" }}
                        >
                            <h1 style={{ paddingTop: "60px", paddingBottom: "30px", color: "white", fontWeight: "bold" }}>03</h1>
                            <p className='graphic-text' style={{ paddingBottom: "60px", color: "white" }}>APPLY</p>
                        </div>
                        <div 
                            className='col flex-grow-3 d-flex flex-column justify-content-center align-items-center HomePageGraphics__responsive-graphic-size'
                            id='perfect-graphic'
                            style={{ marginTop: "10px", marginBottom: "20px", marginRight: "2%" }}
                        >
                            <h1 style={{ paddingTop: "60px", paddingBottom: "30px", color: "white", fontWeight: "bold" }}>04</h1>
                            <p className='graphic-text' style={{ paddingBottom: "60px", color: "white" }}>PERFECT</p>
                        </div>
                    </div>
                </div>
                <div className='col-md-1 d-none d-md-block' style={{ backgroundColor: "#E5E4E2" }}></div>
            </div>

        </div>
    )
}

export default HomePageGraphics